import React, { useContext, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { graphql, Link } from "gatsby"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import paginationFactory from "react-bootstrap-table2-paginator"

import PageWrapper from "../components/PageWrapper"
import Hero from "../sections/common/Hero"
import Support from "../sections/common/Support"
import Contact from "../sections/common/Contact"
import { Section, Title, Text, Box } from "../components/Core"

import GlobalContext from "../contexts/GlobalContext"
import { allLangs } from "../utils/configClient"
import { slugHome } from "../utils/slugs"

import ProductAccordion from "../components/ProductAccordion"
import ProductSlider from "../components/ProductSlider"
import bgImg from "../assets/images/bg3.jpg"

const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total ml-2">
    Showing {from} to {to} of {size} Results
  </span>
)

const { SearchBar } = Search
const columns = [
  {
    dataField: "profile",
    text: "Profile",
    sort: true,
  },
  {
    dataField: "category",
    text: "Category",
    sort: true,
  },
  {
    dataField: "height_mm",
    text: "Height (mm)",
    sort: true,
  },
  {
    dataField: "height_in",
    text: "Height (in)",
    sort: true,
  },
  {
    dataField: "width_mm",
    text: "Width (mm)",
    sort: true,
  },
  {
    dataField: "width_in",
    text: "Width (in)",
    sort: true,
  },
  {
    dataField: "links",
    text: "Link",
  },
]

const defaultSorted = [
  {
    dataField: "profile", // if dataField is not match to any column you defined, it will be ignored.
    order: "asc", // desc or asc
  },
]

const ProductDatabase = ({ data }) => {
  const gContext = useContext(GlobalContext)

  useEffect(() => {
    if (gContext.currentLang !== allLangs.cn) {
      gContext.setCurrentLang(allLangs.cn)
    }
  }, [])

  const dbNodes = data.allPrismicProductDatabase.nodes
  const pData = data.prismicDatabasePage?.data

  const addTrailingZero = (currNum, dPoint = 3) => {
    return currNum.toLocaleString("en", {
      useGrouping: false,
      minimumFractionDigits: dPoint,
    })
  }

  const products = dbNodes.map(
    ({
      data: {
        title,
        category,
        height_mm,
        height_in,
        width_mm,
        width_in,
        pdf,
        iges,
      },
    }) => {
      return {
        profile: title ? title.text : "",
        category: category ? category.text : "",
        height_mm: `${addTrailingZero(height_mm, 2)} mm`,
        height_in: `${addTrailingZero(height_in, 3)} in`,
        width_mm: `${addTrailingZero(width_mm, 2)} mm`,
        width_in: `${addTrailingZero(width_in, 3)} in`,
        links: (
          <div>
            <>
              {pdf.url ? (
                <a href={pdf.url} target="_blank">
                  PDF
                </a>
              ) : (
                ""
              )}
            </>
            <>{pdf.url && iges.url ? "|" : ""}</>
            <>
              {iges.url ? (
                <a href={iges.url} target="_blank">
                  IGES
                </a>
              ) : (
                ""
              )}
            </>
          </div>
        ),
      }
    }
  )

  const { btn_home } = data.prismicCommon?.data

  const optionsPagination = {
    showTotal: true,
    paginationTotalRenderer: customTotal,
  }

  return (
    <>
      <PageWrapper>
        <Hero bgImg={pData?.cover_image.url || bgImg}>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                {pData?.title && (
                  <Title variant="hero" color="light">
                    {pData?.title.text}
                  </Title>
                )}
                <Box mt={4}>
                  <div className="d-flex my-3 justify-content-center">
                    <div className="text-white font-weight-bold">
                      {">>"}
                      <Link
                        to={slugHome(gContext.currentLang)}
                        className="text-white font-weight-bold"
                      >
                        {btn_home ? btn_home.text : "Home"}
                      </Link>
                    </div>
                  </div>
                </Box>
              </Col>
            </Row>
          </Container>
        </Hero>
        <Section
          pt={["45px !important", null, null, "60px !important"]}
          className="pb-5"
        >
          <Container fluid>
            <Row className="justify-content-center">
              <Col lg="9" className="pb-5 pb-lg-0">
                <Box mb="40px">
                  <div className="d-flex my-3 justify-content-left">
                    <div className="font-weight-bold">
                      {">>"}
                      <Link
                        to={slugHome(gContext.currentLang)}
                        className="font-weight-bold breadcrumb-link "
                      >
                        {btn_home ? btn_home.text : "Home"}
                      </Link>
                    </div>
                    <div className="font-weight-bold ml-2">
                      {">>"}
                      <Link
                        to={slugHome(gContext.currentLang)}
                        className="font-weight-bold breadcrumb-link "
                      >
                        {pData?.title ? pData?.title.text : "Product Database"}
                      </Link>
                    </div>
                  </div>
                </Box>

                <Box
                  css={`
                    .page-item.active .page-link {
                      color: white !important;
                    }
                  `}
                >
                  <ToolkitProvider
                    bootstrap4
                    keyField="profile"
                    data={products}
                    columns={columns}
                    search
                  >
                    {props => (
                      <div
                        css={`
                          table thead {
                            background: ${({ theme }) =>
                              theme.colors.text} !important;
                            th {
                              color: #fff !important;
                            }
                          }
                        `}
                      >
                        <span className="mr-3 font-weight-bold">Search</span>
                        <SearchBar {...props.searchProps} />
                        <hr />
                        <BootstrapTable
                          {...props.baseProps}
                          pagination={paginationFactory(optionsPagination)}
                          defaultSorted={defaultSorted}
                          defaultSortDirection="asc"
                          classes="table-responsive-md table-striped table-bordered table-hover"
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </Box>
              </Col>
              <Col lg="3">
                <ProductAccordion />
                <ProductSlider />
              </Col>
            </Row>
          </Container>
        </Section>
        <div id="support">
          <Support />
        </div>
        <div id="contact">
          <Contact />
        </div>
      </PageWrapper>
    </>
  )
}
export default ProductDatabase

export const query = graphql`
  query($lang: String!) {
    prismicDatabasePage(lang: { eq: $lang }) {
      data {
        title {
          text
        }
        cover_image {
          url
        }
      }
    }

    prismicCommon(lang: { eq: $lang }) {
      data {
        btn_home {
          text
        }
      }
    }

    allPrismicProductDatabase {
      nodes {
        data {
          title {
            text
          }
          pdf {
            url
          }
          iges {
            url
          }
          category {
            text
          }
          height_in
          height_mm
          width_mm
          width_in
        }
      }
    }
  }
`
